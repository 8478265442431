import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pot aparèixer aferrat a la fusta de la qual quan és jove es separa fàcilment, o en forma resupinada. Té porus i tubs disposats en forma de pisos de dalt a baix. Quan és jove té color blanc i després torna color crema o ocre. Les espores són cilíndriques i en forma de ronyó, llises, de 4-5 x 1-1,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      